@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.typewriter {
  margin: 0;
  color: #51f565;
  font-weight: 700;
  font-size: 35px;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
}

.timeline {
  background-color:#010401;
  padding: 20px;
  border-top: 3px solid #fff;
}


.title {
  font-size: 2em;
  text-align: center;
  color: white;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  color: white;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: rgb(201, 251, 255);
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

.navActive {
  border-bottom: 2px solid #51f565;
  color: #51f565;
}


@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}


/* //ANIMATION */

